<template>
    <div v-if="isReady">
        <div v-if="showSignup && existingPersons.length > 0">
            <p>Please select existing person</p>
            <div class="form-group mb-2" >
                <div class="col" v-for="person in existingPersons">
                    <button type="button" class="btn btn-o365-login mt-1" @click="selectPerson(person)">{{person.firstName}} {{person.lastName}}</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-o365-login mt-1" @click="selectPerson(null)">{{ $t('Its not me') }}</button>
                </div>
            </div>
        </div>
        <div v-else>
            <div>
                <p v-if="!isInviteUrl && !showSignup">{{ $t('Change your password') }}</p>
                <p v-else-if="isInviteUrl">{{ $t('Enter the desired password. The password must be at least 8 characters long, contain numbers as well as small and large letters.') }}</p>
                <p v-else-if="showSignup">{{ $t('Please fill all fields') }}</p>
                <p v-else>Enter the desired password. <br>The password must be at least 8 characters long, contain numbers as well as small and large letters.</p>
            </div>
            <div v-if="!isInviteUrl && !showSignup" class="form-floating mb-2 mt-2">
                <input type="text" name="username" v-model="username" id="username" disabled class="form-control" placeholder="name@example.com" />
                <label for="username">{{$t(serviceNames[state.currentProvider] ?? 'Username')}}</label>
            </div>
            <div v-if="showSignup && !isInviteUrl">
                <div class="form-group mb-2 mt-2" >
                    <label for="firstName">{{$t('First Name')}}</label>
                    <input type="text" name="firstName" v-model="firstName" :disabled="primKey.length" id="firstName" :class="{ 'is-invalid' : state.errors?.firstName }" class="form-control" placeholder="First Name"/>
                    <span class="invalid-feedback mt-0" v-if="state.errors?.firstName">{{ state.errors?.firstName }}</span>
                </div>

                <div class="form-group mb-2 mt-2">
                    <label for="lastName">{{$t('Last Name')}}</label>
                    <input type="text" name="lastName" v-model="lastName" :disabled="primKey.length" id="lastName" :class="{ 'is-invalid' :state.errors?.lastName }" class="form-control" placeholder="Last Name" />
                    <span class="invalid-feedback mt-0" v-if="state.errors?.lastName">{{ state.errors?.lastName }}</span>
                </div>

                <div class="form-group mb-2 mt-2">
                    <label for="mobile">{{$t('Mobile')}}</label>
                    <input type="text" name="mobile" v-model="mobile" :disabled="validatePhone(username) || primKey.length && mobile.length" id="mobile" :class="{ 'is-invalid' : state.errors?.mobile }" class="form-control" placeholder="Mobile"/>
                    <span class="invalid-feedback mt-0" v-if="state.errors?.mobile">{{ state.errors?.mobile }}</span>
                </div>

                <div class="form-group mb-2 mt-2">
                    <label for="email">{{$t('Email')}}</label>
                    <input type="text" name="email" v-model="email" :disabled="validateEmail(username)" id="email" :class="{ 'is-invalid' : state.errors?.email }" class="form-control" placeholder="Email" />
                    <span class="invalid-feedback mt-0" v-if="state.errors?.email">{{ state.errors.email }}</span>
                </div>
            </div>

            <div class="form-group mb-2 mt-2">
                <label for="password" style="font-size:small">{{$t('New Password')}} <span v-if="password.length > 0" :class="`score-${passwordStrength}`">({{ passwordStrength < 3 ? $t('Weak') : (passwordStrength <= 4 ? $t('Medium') : $t('Strong')) }})</span></label>
                <input v-on:keyup.enter="changePassword" v-on:keyup="validatePasswordStreanch" :type="showNewPassword ? 'text' : 'password'" class="form-control" id="password" v-model="password" :class="{ 'is-invalid' : state.errors?.password }" autocomplete="current-password" required placeholder="Password">
                <button type="button" class="btn btn-show-pass" :class="{ 'd-none' : state.errors?.password }" @click="showNewPassword = !showNewPassword">
                    <i class="bi" :class="showNewPassword ? 'bi-eye-slash-fill' : 'bi-eye-slash'"></i>
                </button>
                <span class="invalid-feedback mt-0" v-if="state.errors?.password">{{ state.errors?.password }}</span>
            </div>
            

            <div class="form-group mb-2 mt-2">
                <label class="control-label" for="confirm" style="font-size:small">{{$t('Confirm New Password')}}</label>
                <input v-on:keyup.enter="changePassword" :type="showConfirmPassword ? 'text' : 'password'" class="form-control" id="confirm" v-model="confirm" :class="{ 'is-invalid' : state.errors?.confirm }" autocomplete="current-password" required placeholder="New Password">
                <button type="button" class="btn btn-show-pass" :class="{ 'd-none' : state.error?.confirm }" @click="showConfirmPassword = !showConfirmPassword">
                    <i class="bi" :class="showConfirmPassword ? 'bi-eye-slash-fill' : 'bi-eye-slash'"></i>
                </button>
                <span class="invalid-feedback mt-0" v-if="state.errors?.confirm">{{ state.errors?.confirm }}</span>
            </div>
            
            <ErrorComponent :key="state.updated" :errors="errors" :capsLockOn="capsLockOn"/> 

            <div class="form-group col mt-2" >
                <button type="button" class="btn btn-o365-login" @click="submit">{{$t('Confirm')}}</button>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center my-3">
            <a type="button" @click="resetState">{{$t('Back')}}</a>
        </div>
    </div>
</template>

<style scoped>
    .btn-show-pass {
        position: absolute;
        top: 5px;
        bottom: 5px;
        right: 10px;
        display: none;
        font-size: 20px;
    }
    .form-floating:not(input.is-invalid):hover .btn-show-pass {
        display: block;
    }
    
    .score-0, .score-1, .score-2 {
        color: #E74C3C;
    }
    .score-3, .score-4 {
        color: #F1C40F;
    }
    .score-5 {
        color: #27AE60;
    }
</style>

<script setup lang="ts">
    import { ref, computed, onMounted, inject } from 'vue';
    import { createRequest, getQueryParam, hasQueryParam, validatePhone, validateEmail, loadReCaptcha, MultiFactorStates, serviceNames } from 'o365.modules.Login.shared.js';
    import ErrorComponent from 'o365.vue.components.Login.Errors.vue';

    const isBusy = inject('isBusy') as Function;    
    const resetState = inject('resetState') as Function;
    const updateState = inject('updateState') as Function;
    const setErrors = inject('setErrors') as Function;
    const authenticated = inject('authenticated') as Function;

    const props = defineProps({
        state: { type: Object, required: true}, 
        errors: { type: Object, required: false, default: {} }, 
        capsLockOn: { type: Boolean, required: true, default: false }, 
    });

    onMounted(async() => {
        console.log("signup maunted:", props);
        loadReCaptcha(props.state.siteKey);
        if(showSignup.value && !isInviteUrl.value){
            await getExistingPersonsAsync();
        }
        await handleParticipateRequest();
        if(!username.value){
            username.value = props.state.username;
        }
        if(validatePhone(username.value)){
            mobile.value = username.value;
        } else if(validateEmail(username.value)){
            email.value = username.value;
        }
        
        isReady.value = true;
    });
    
    const shouldHandleParticipateRequest = hasQueryParam('smsToken') && props.state.authentication?.smsToken?.enabled == true;
    const isRecaptchaConfigured = computed(() => props.state.siteKey && props.state.siteKey.length > 0);
    const newAccount = getQueryParam('newaccount');
    const newAccountToken = getQueryParam('token');
    const isInviteUrl = ref(newAccount?.length > 0 && newAccountToken?.length > 0);

    const showSignup = computed(() => props.state.action === 'signup');
    const showNewPassword = ref(false);
    const showConfirmPassword = ref(false);

    const isReady = ref(false);
    const primKey = ref('');

    const username = ref('');
    const firstName = ref('');
    const lastName = ref('');
    const mobile = ref('');
    const email = ref('');

    const existingPersons = ref([]);

    const password = ref('');
    const confirm = ref('');
    const passwordStrength = ref(0);
    
    async function handleParticipateRequest(){
        if(shouldHandleParticipateRequest && props.state.multiFactorState === MultiFactorStates.Verified && existingPersons.value?.length == 0){
            isBusy(true);
            await submit();
        }        
        isBusy(false);
    }

    let passwordRules = [
        { regex: /.{14,}/},
        { regex: /[0-9]/},
        { regex: /[a-z]/},
        { regex: /[A-Z]/},
        { regex: /[^A-Za-z0-9]/}
    ];

    function validatePasswordStreanch(){
        let score = 0;
        passwordRules.forEach((item, i) => {
            score += (item.regex.test(password.value) ? 1 : 0);
        });
        passwordStrength.value = score;
    }

    function validate():boolean {

        if(showSignup.value && !isInviteUrl.value){
            if(!firstName.value) {
                setErrors({firstName: 'FirstName field is required'});
                return false;
            }
            if(lastName.value.length <= 0) { 
                setErrors({lastName: 'LastName field is required'});
                return false;
            }
            if(mobile.value?.length > 0 && !validatePhone(mobile.value)){
                setErrors({ mobile: 'Invalid Mobile' });
                return false;
            }
            if(email.value?.length > 0 && !validateEmail(email.value)) {
                setErrors({email: 'Invalid Email'});
                return false;
            }
        }

        if(!shouldHandleParticipateRequest && password.value.length < 8 ) {
            setErrors({password: 'Password is too short'});
            return false;
        }

        if(!shouldHandleParticipateRequest && confirm.value != password.value){
            setErrors({confirm: 'Passwords do not match'});
            return false;
        }
        return true;
    }

    async function submit() {
        setErrors({});
        if(!validate()) return;
        try{
            isBusy(true);
            
            let data =  {}
            if(!shouldHandleParticipateRequest) {
                data['password'] = password.value,
                data['confirm'] = confirm.value
            }
            if(isInviteUrl.value){
                data['newaccount'] = newAccount;
                data['token'] = newAccountToken;
            } else {
                data['username'] = username.value;
            }

            if(showSignup.value && !isInviteUrl.value) {
                data['firstName'] = firstName.value;
                data['lastName'] = lastName.value;
                data['mobile'] = validatePhone(username.value) ? username.value : mobile.value;
                data['email'] = validateEmail(username.value) ? username.value : email.value;
                if(primKey.value.length){
                    data['primKey'] = primKey.value;
                }
            }

            if(showSignup.value) {
                if(isRecaptchaConfigured.value){
                    data['reToken'] = await grecaptcha.execute(props.state.siteKey, {action: 'submit'});
                }
                if(isInviteUrl.value){
                    await changePassword(data);
                } else {
                    await createUser(data);
                }
                
            } else if(props.state.action == 'reset' && props.state.scheme == 'SmsToken'){
                await signSmsUser(data);
            } else {
                await changePassword(data);
            }            
        } finally {
            isBusy(false);
        }
    }

    async function getExistingPersonsAsync(){
        isBusy(true);
        const response = await createRequest('/api/login/signup/person-info', null);
        if(response.ok){
            existingPersons.value = await response.json();
        }
        isBusy(false);
    }

    async function selectPerson(selectedPerson){
        if(selectedPerson){
            firstName.value = selectedPerson.firstName;        
            lastName.value = selectedPerson.lastName;
            email.value = selectedPerson.email;
            mobile.value = selectedPerson.mobileNo;
            primKey.value = selectedPerson.primKey;
        }
        
        existingPersons.value = [];
        if(shouldHandleParticipateRequest){
            await submit();
        }
    }

    async function changePassword(data){
        const response = await createRequest('/api/login/mfa', data);
        if(response.ok){
            var json = await response.json();
            handleSuccess(json);            
        } else if(response.status == 400){
            var json = await response.json();
            handleError(json);
        }
    }

    async function createUser(data){
        const response = await createRequest('/api/login/create', data);
        if(response.ok){
            var json = await response.json();
            handleSuccess(json);
        }
        if(response.status == 400){
            var json = await response.json();
            handleError(json);
        }
    }

    async function signSmsUser(data){
        const response = await createRequest('/api/login/sms', data);
        if(response.ok){
            var json = await response.json();
            handleSuccess(json);
        }
        if(response.status == 400){
            var json = await response.json();
            handleError(json);
        }
    }

    function handleSuccess(json){
        if(json.multiFactorState == MultiFactorStates.Verified || json.state.multiFactorState == MultiFactorStates.Verified){
            props.state.isAuthenticated = true;
            authenticated();
        } else {
            // props.state.username = username.value;
            // props.state.multiFactorState = json.multiFactorState;           
            // props.state.action = 'login';
            updateState(json.state);
        }
    }

    function handleError(json){
        setErrors(json.errors);
        updateState(json.state);
        props.state["updated"] = Date.now();
    }

</script>